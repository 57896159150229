import React, {useContext, useEffect} from "react";
import {CartContext} from '../store/cartState';
import Layout from "../components/Layout/layout"
import Products from "../components/Products/products";

const ShopPage = (props) => {  
  const {toggleCart} = useContext(CartContext);

  useEffect(() => {
    try{
      if(props.location.state !== undefined 
        && props.location.state !== null 
        && props.location.state.closeCart === true){
        toggleCart(); //Do not add this to the deps array, it will go mental
      }
    }
    catch{}
    
  }, [props])

  return (
    <Layout>
      <Products />
    </Layout>
  )

};

export default ShopPage;
import { useStaticQuery, graphql } from "gatsby"

const GetProducts = () => {
    const data = useStaticQuery(graphql`
    query {
      AromaTouchKit: file(relativePath: { eq: "AromaTouchKit.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      HomeEssentials: file(relativePath: { eq: "HomeEssentials.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      KidsKit: file(relativePath: { eq: "KidsKit.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      MoodManageKit: file(relativePath: { eq: "MoodManagement.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      YogaKit: file(relativePath: { eq: "YogaKit.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      FamilyKit: file(relativePath: { eq: "FamilyEssentials.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      SpaKit: file(relativePath: { eq: "SpaKit.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      Petal: file(relativePath: { eq: "PetalDiffuser.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      EssentialAromaticsKit: file(relativePath: { eq: "EssentialAromaticsKit.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      Skin: file(relativePath: { eq: "Verage.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      Intro: file(relativePath: { eq: "IntroKit.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      Athlete: file(relativePath: { eq: "AthleteKit.png" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }

      NaturalSolutionsKit: file(relativePath: { eq: "NaturalSolutionsKit.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
                  ...GatsbyImageSharpFluid

          }
        }
      }
    }
`)

const products = [
    {
      name: 'Home Essentials', 
      image: data.HomeEssentials,
      id:'prod_IPKvBHLaHO6JPk', 
      // id:'prod_IPiSDv9tDrUOGW', 
      originalPrice:246,
      price: 224,
      includesMembership: true,
      ingredients: 'Frankincense (10ml), Lavender (10ml), Lemon (10ml), Melaleuca (10ml), Oregano(10ml), Peppermint(10ml) , doTERRA Breathe®(10ml), DigestZen®(10ml), doTERRA On Guard®(10ml), Deep Blue®(5 mL)',
      description: 'Includes everything you need to start your essential oil journey at home. This collection includes ten of the most popular, useful and versatile oils, a petal diffuser and information welcome pack',
      application: ['Petal Diffuser', 'The doTERRA Essentials Booklet'],      
   },
    {
        name: 'Aroma Touch Kit', 
        image: data.AromaTouchKit, 
        id:'prod_IPKtWztqQClJ5N',
        // id:'prod_IPG0DFhzfTHjYM',
        originalPrice:156,
        price: 142, 
        includesMembership: true,
        ingredients: 'Fractionated Coconut Oil (115 mL), doTERRA Balance® (5ml), Lavender (5ml), Melaleuca (5ml), OnGuard® (5ml), AromaTouch® (5ml), Deep Blue® (5ml), Wild Orange (5ml), Peppermint (5ml)',
        description: 'Experience a variety of essential oils that can also be used for the AromaTouch Technique.',
        application: ['Petal Diffuser', 'The doTERRA Essentials Booklet']
    },    
    {
        name: 'Kids Kit', 
        image: data.KidsKit, 
        id:'prod_IPKw4vaL138Q1d',
        // id:'prod_IPiQ9rUljBQRaw',
        price: 115,
        includesMembership: false,
        ingredients: 'Seven unique 10mL roll-ons Thinker™, Calmer™, Stronger™, Tamer™, Rescuer™, Steady™, Brave™',
        description: 'A unique collection of essential oil blends formulated with little ones in mind. Now with ten loops, add more of your favorite roll-ons into your Kids Collection bag.',
        application: ['Seven silicone toppers with carabiners', 'Instructional flashcards', 'Carrying case with carabiner']
    },    
    {
        name: 'Family Essentials Kit', 
        image: data.FamilyKit, 
        id:'prod_IPKwEKd2xdEWTj',
        // id:'prod_IPiQ1qZJyRbd8q',
        price: 108,
        includesMembership: false,
        ingredients: 'This kit contains 5 mL bottles of Lavender, Lemon, Peppermint, Tea Tree, Oregano, Frankincense, Deep Blue®, doTERRA Breathe®, DigestZen®, doTERRA On Guard®',
        description: 'Contains 10 essential oils and blends — the "feel better" essentials that parents need on a daily basis to care for their families.',
        application: ['Quick usage guide for each essential oil and blend included.']
    },
    {
      name: 'Yoga', 
      image: data.YogaKit, 
      id:'prod_IPKxB3xIVpJi8d',
      // id:'prod_IPiPLwTkSvDx8j',
      price: 58,
      ingredients: 'Anchor, Align, and Arise are the perfect blends to enhance your life and yoga practice. These blends provide aromas to steady, center, and enlighten your spirit through every breath while strengthening and stretching your body.',
      description: 'An exclusive trio of essential oil blends designed by doTERRA for the yogi in you',
      application: []
    },
    {
      name: 'Athlete', 
      image: data.Athlete, 
      id:'prod_IPm9M5JULoCDKV',
      // id:'prod_IPm99WT6AX0bji',
      price: 73,
      ingredients: 'The kit includes key products for those with an athletic lifestyle: 5 ml bottles of do–TERRA Breathe, do–TERRA On Guard, Lavender, Melaleuca (Tea Tree), and Peppermint and a 120 ml tube of Deep Blue Rub presented in a compact, reusable zippered pouch',
      description: 'Whether in competitive arenas or a local gym, activity is a cornerstone of health. As anyone who has ever donned workout shoes will tell you, athletic activity can tax your body. The do–TERRA Athlete’s Kit is designed with athletes in mind, and is perfect for supporting healthy lifestyles from competitive to health-minded.',
      application: ['With the Athlete’s Kit, you can enjoy implementing athleticism in your life']
    },
    {
      name: 'Mood Management', 
      image: data.MoodManageKit, 
      id:'prod_IPKyg3yJuyougO',
      // id:'prod_IPiO5eOwM8TnP3',
      price: 102,
      ingredients: '15 ml bottles of Balance, Lavender Peace, Citrus Bliss, Elevation',
      description: 'Elevation for those that wanting to experience the beautiful fragrance and benefits of these specially blended oils.',
      application: []
    },
    {
      name: 'Spa Kit', 
      image: data.SpaKit, 
      id:'prod_IPKzWhis3Zsk7r',
      // id:'prod_IPiOseoaAd6vIg',
      price: 100,
      ingredients: 'dōTERRA SPA Refreshing Body Wash, Citrus Bliss® Hand Lotion, Hand & Body Lotion, Exfoliating Body Scrub, Detoxifying Mud Mask, Replenishing Body Butter, Serenity® Bath Bar, Moisturising Bath Bar, SPA Lip Balms',
      description: 'Pamper yourself from head to toe with the Total dōTERRA SPA Kit.',
      application: []
    },
    {
      name: 'Essential Aromatics Kit', 
      image: data.EssentialAromaticsKit, 
      id:'prod_IPKzt9hb2iBWHH',
      // id:'prod_IPiNPqslFHAb51',
      price: 138,
      ingredients: 'doTERRA Motivate™ Encouraging Blend. doTERRA Cheer™ Uplifting Blend. doTERRA Passion™ Inspiring Blend. doTERRA Forgive™ Renewing Blend. doTERRA Console™ Comforting Blend. doTERRA Peace™ Reassuring Blend.',
      description: 'The doTERRA Emotional Aromatherapy System is a revolutionary organization of aromatic plant families around a continuum of emotions for a simple, profound approach to using fragrant essential oils in emotional aromatherapy applications.',
      application: ['Individual essential oils can be used with great effectiveness']
    },
    {
      name: 'Natural Solutions Kit', 
      image: data.NaturalSolutionsKit, 
      id:'prod_IPL42PigZTCi1R',
      // id:'prod_IPiM5brs4Tbayb',
      price: 570,
      ingredients: 'Includes Lumo Diffuser',
      description: 'This is the complete package for those that want the best of everything to start. Several pure essential oils, several blended essential oils, immune boosting oils and products and much more',
      application: [
        'Essential Oils - 15ml of Frankincense, Lavender, Lemon, Melaleuca, Peppermint, Wild Orange', 
        'Essential Oil Blends - 15ml of doTERRA Air®, AromaTouch®, doTERRA Balance®, doTERRA On Guard®, PastTense®, doTERRA Serenity®, ZenGest®',
        'OnGuard® Collection - OnGuard Beadlets, OnGuard Foaming Hand Wash & Two Dispensers, OnGuard Softgels, OnGuard Whitening Toothpaste',
        'Other - doTERRA Air™ Vapour Stick, Correct-X®, ZenGest TerraZyme®, Deep Blue® Rub, Fractionated Coconut Oil, Lumo Diffuser, PB Assist+®, doTERRA Salon Essentials® Protecting Shampoo, doTERRA Salon Essentials® Smoothing Conditioner, doTERRA Essentials Booklet, Lifelong Vitality Pack®, Wooden Box'        
      ]        
  },  
  {
    name: 'Veráge Skin Care Kit', 
    image: data.Skin, 
    id:'prod_IPm89ficUkRVCS',
    // id:'prod_IPm8esN7de1Ucp',    
    price: 115,
    ingredients: 'The advanced plant technology used in Veráge promotes the appearance of an optimal lipid balance—the same balance found in healthy, youthful-looking skin. Each product is composed of true gifts from the Earth: nourishing plant extracts, pure and potent CPTG® essential oils, and select natural ingredients.',
    description: 'The doTERRA Veráge Skin Care Collection delivers results you can see and feel, naturally',
    application: ['Toner', 'Cleanser', 'Hydrating Serum', 'Moisturizer']
  },
  {
    name: 'Introductory Kit', 
    image: data.Intro, 
    id:'prod_IPLJqnzYAVJYGG',
    // id:'prod_IPiKSe2jPtBfqx',    
    price: 30,
    ingredients: 'Contains 5ml of Lavender, Lemon & Peppermint',
    description: '3 amazing introductory oils',
    application: []
  },
  {
    name: 'Petal Diffuser', 
    image: data.Petal, 
    id:'prod_IPLKGTQPfK2VoJ',
    // id:'prod_IPiINNEBCGjU5G',
    price: 40,
    ingredients: 'With ultra-fine mists that reach up to 30 square metres, the Petal 2.0 will ensure you get the most out of diffusing our CPTG Certified Pure Tested Grade® Oils. The Petal enhances the longevity of oils with diffuser settings of 2 and 6 hours continuously and 12 hours intermittently, including the ability to have the night light feature on or off. As a gift for loved ones or as a treat for yourself, the Petal is modern and compact, and will give you complete control over how you diffuse oils in your home',
    description: 'dōTERRA Petal Diffuser 2.0',
    application: []
  }
]

    return products;
}

export default GetProducts;
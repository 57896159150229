import React from "react"
import styled from "styled-components"
import Product from './product';
import { HeadingSlim, TextSlim } from '../Layout/pageParts';
import GetProducts from '../../store/api/reapShop';

const Container = styled.div`
    display: flex;
    justify-content:center;
    align-items: baseline;
    align-self:stretch;
    flex-wrap: wrap;
    flex:1;    
`;

const RootContainer = styled.div`
    display: flex;
    flex-direction:column;
    align-items: center;
    

    margin-left: 15px;
    margin-right: 15px;
    margin-top: 50px;
    margin-bottom:10px;
    padding-right:50px;
`;

const Products = () => {

const products = GetProducts(); 

const productPreviews = products.map(p => <Product key={p.name} {...p} />)

  return (
    <RootContainer>
        <HeadingSlim>Amazing Collections for you and your family</HeadingSlim>
        <TextSlim>The best way to start your essential oils journey. All include continued enducation and wellness support from me!</TextSlim>
        <Container>       
            {productPreviews}
        </Container>
    </RootContainer>
  )

}

export default Products

import React from "react"
import Img from "gatsby-image";
import styled from "styled-components"
import { Title, Text, NavLink,  } from '../Layout/pageParts';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;    
    width:250px;
    padding-left:10px;
    padding-right:10px;
    margin-top:10px;
    margin-top:10px;
`;

const Product = ({id, name, image, price, originalPrice, ingredients, description, application}) => {

  return (
    <NavLink to={`/productDetails?name=${name}`} state={{id, name, image, price, originalPrice, ingredients, description, application}}>
      <Container>       
            <Img alt={`${name} oil`} fluid={image.childImageSharp.fluid} style={{ width: "100%", height: "0%" }} />
            <Title>{name}</Title>
            {originalPrice ? <Title><s>£{originalPrice}</s> Sale £{price}</Title> : <Title>£{price}</Title> }            
            <Text>{description}</Text>
      </Container>
    </NavLink>
  )

}

export default Product
